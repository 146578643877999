/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
  pathname,
  prevRouterProps
}) => {
  if(pathname === (prevRouterProps && prevRouterProps.location.pathname)) {
    return false;
  }
  //const currentPosition = getSavedScrollPosition(location)
  //const queriedPosition = getSavedScrollPosition({ pathname: location.pathname })

  //window.scrollTo(...(currentPosition || [0, 0]))

  return true
}